import React, { useContext, createContext } from "react";
import axios from 'axios';

const CartContext = createContext()

export default function CartContextProvider({ children }) {
    const [cartItems, setCartItems] = React.useState([])
    const [isSubmited, setIsSubmited] = React.useState(false)
    const [currentItem, setCurrentItem] = React.useState('')
    const [currentOrder, setCurrentOrder] = React.useState('')
    const [open, setOpen] = React.useState(false)


    React.useEffect(() => {
        const storage = JSON.parse(localStorage.getItem("cartItems"))

        if (storage !== null)

            setCartItems(storage)

    }, [])

    //ADD ITEM TO CART

    const addToCart = (item) => {
        // console.log(item)

        const exist = cartItems.find((elem) => elem.id === item.id)


        setCartItems(s => ([...s, item]))
        localStorage.setItem("cartItems", JSON.stringify([...cartItems, item]))
        // if (exist === undefined) {

        //     setCartItems(s => ([...s, item]))
        //     localStorage.setItem("cartItems", JSON.stringify([...cartItems, item]))
        // }

    }



    //REMOVE ITEM FROM CART
    const removeCartItem = (id) => {

        setCartItems(
            cartItems.filter((x) => x.id !== id)
        )
        localStorage.setItem("cartItems", JSON.stringify(cartItems.filter((x) => x.id !== id)))

    }

    //Forgot Password
    const resetCart = () => {

        setCartItems([])
        localStorage.removeItem("cartItems")

    }






    return (<CartContext.Provider value={{
        cartItems, setCartItems, addToCart, removeCartItem, isSubmited, setIsSubmited, open, setOpen,
        currentItem, setCurrentItem, resetCart, currentOrder, setCurrentOrder
    }}>
        {children}
    </CartContext.Provider>)

}
export const useCartContext = () => {
    return useContext(CartContext)
}