import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import UpdataPassword from './UpdataPassword'
import { Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import UserForm from 'components/Form/AgencyDetailForm/UserForm'
export default function Settings() {

  return (
    <Grid container >

      <Grid item xs={12} sm={12} md={12} lg={12}>

        <UpdataPassword />

      </Grid>
      {/* <Grid item xs={12} sm={12} md={7} lg={7}>
        <Paper elevation={3} sx={{ p: 3 }}>

          <UserForm />
        </Paper>
      </Grid> */}
    </Grid>
  )
}
