import * as React from 'react';
import { useCartContext } from 'context/CartContext';
import swal from 'sweetalert'
import FilterForm from './FilterDiv/FilterForm';
import { useTranslation } from 'react-i18next';
import PaginationTable from 'components/Table/PaginationTable';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';


export default function Products() {
    //context
    const { isSubmited, setIsSubmited } = useCartContext()


    //map will place here to map rows


    const { t } = useTranslation()


    //trigger form when submitted
    if (isSubmited) {

        swal(
            {
                title: `${t("sucess")}`,
                text: `${t("added_to_cart")}`,
                icon: 'success',
                // button: 'ok'
                timer: 3000,
                buttons: false
            }

        )
        setIsSubmited(false)
    }

    return (
        <>

            <Grid container spacing={2}>
                {/* <CartFileUpload/> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>


                    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>

                        <FilterForm />
                    </Paper>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>



                    <PaginationTable />

                </Grid>
            </Grid>


        </>
    );
}
