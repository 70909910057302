import * as React from 'react';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { Box } from '@mui/system';
import { useAgencyContext } from 'context/AgencyContext';
import { useTranslation } from 'react-i18next';

export default function AgencyCard() {
    const { agencyData } = useAgencyContext()
    // const agencyData=JSON.parse(localStorage.getItem('agency'))
    const { t } = useTranslation()


    return (
        <CssVarsProvider>

            <Box>

                <CardOverflow>
                    <Typography variant='h3' textAlign='center' color='primary' sx={{ fontWeight:400, fontSize: 22 }}>
                        Fatturazione
                    </Typography>
                </CardOverflow>
                <Divider />
                <Box sx={{ display: `${agencyData.type === "private" ? 'flex' : 'none'}`, justifyContent: 'space-between', p: 2,flexWrap:'nowrap' }}>
                    <Box >
                        <Typography level="h2"  sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {t('full_name')}:
                        </Typography>
                        <Typography level="h2" sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {t('lastName')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            Codice Fiscale:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('address')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('city')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('CAP')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('village')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('proviance')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            Type:
                        </Typography>
                    </Box>

                    <Box>
                        <Typography level="h2" sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {agencyData.name}
                        </Typography>
                        <Typography level="h2" sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {agencyData.lastName}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.codiceFiscale
                            }
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.address
                            }
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.city}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.village}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.cap}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.proviance}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {/* {agencyData.type} */}
                            Privato
                        </Typography>
                    </Box>
                </Box>




     


                <Box sx={{ display: `${agencyData.type === "organization" ? 'flex' : 'none'}`, justifyContent: 'space-between',flexWrap:'nowrap' }}>
                    <Box >
                        <Typography level="h2" sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {t("orgnization")}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            Partia IVA:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('address')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('city')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            CAP:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('state')}:
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {t('proviance')}:
                        </Typography>

                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            Type:
                        </Typography>
                    </Box>

                    <Box>
                        <Typography level="h2" sx={{ fontSize: 'sm', mt: 0.5, mb: 2 }}>
                            {agencyData.orgnization}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.vatNumb}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.address}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.city}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.zip}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.country}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {agencyData.state}
                        </Typography>
                        <Typography level="body2" sx={{ fontWeight: '600', mt: 0.5, mb: 2 }}>
                            {/* {agencyData.type} */}
                            {t("orgnization")}
                        </Typography>
                    </Box>
                </Box>

                
                   
            </Box>
           
        </CssVarsProvider>
    );
}