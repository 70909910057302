import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { useFilterContext } from 'context/FilterContex';

// function valuetext(value) {
//     return `${value}°C`;
// }


export default function DaSlider({ isReset, setIsReset }) {
    const { daValue, setDaValue } = useFilterContext()
    const [value, setValue] = React.useState([0, 100]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setDaValue(newValue)
    };

    React.useEffect(() => {
        if (isReset) {
            setValue([0, 100])
            setIsReset(false)
            setDaValue([0,100])
        }
    }, [isReset])


    // // daValue === '' && setValue([0, 100])
    // // React.useEffect(() => {
    //     console.log(value)
    // }, [daValue])

    return (
        <Box sx={{ width:'100%' }}>
            <Typography id="input-slider" variant='subtitle2' align='center' >
                DA: {daValue[0] === '' ? 0 : daValue[0]}-{daValue[1] === '' ? 0 : daValue[1]}
            </Typography>
            <Slider
                getAriaLabel={() => 'Temperature range'}
                value={value}
                // sx={{ width: 200 }}
                onChange={handleChange}
            // scale={100}
            // valueLabelDisplay="auto"
            // getAriaValueText={valuetext}
            />
        </Box>
    );
}
