import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { mainListItems, secondaryListItems } from './ListItems';
import ListItems from './DashboardComponents/ListItems';
import { useCartContext } from 'context/CartContext';
import ListItemButton from '@mui/material/ListItemButton';
// import LinearProgressBar from 'components/Progress Bar/LinearProgressBar';`
import { useTranslation } from "react-i18next";
import Logo from '../../assets/img/logo.png'
import { useTheme } from '@mui/material/styles';
import ScreensNavigator from '../ScreensNavigator';
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from 'context/UserAuthContext';
import AppBar from '@mui/material/AppBar';



const drawerWidth = 95;



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      // width: '8rem',
      alignItems: 'center',
      marginTop: '4rem',
      zIndex: 9,
      // top:80,

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        // width:'7rem',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


function DashboardContent() {
  // const cartItems = useSelector((state) => state.CartReducer.cartItems)
  // const dispatch = useDispatch()

  const [open, setOpen] = React.useState(true);
  const theme = useTheme()
  const { isUserAuthenticated } = useUserAuth()
  const {cartItems}=useCartContext()
  const navigate = useNavigate()
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //screen navigation in drawr
  const { t } = useTranslation()



  // const location = useLocation()
  return (
    // <ThemeProvider theme={mdTheme}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="absolute" open={open}> */}
      <AppBar sx={{ marginTop: 0, width: '100%' }}
        position='fixed'
        variant='priamry'
        open={open} >
        <Toolbar
        // sx={{
        //   pr: '24px', 
        // }}
        >
          <img
            src={Logo}
            style={{
              width: '10rem',
              height: '2.5rem',
              alignSelf: 'start',
              position: 'absolute',
              top: 10,
              left: 6,
              right: 0,
              bottom: 0

            }}
          />

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate('/userCart')}
            sx={{
              position: 'absolute',
              right: 22
            }}
          >
            <Badge badgeContent={cartItems.length} color="error">
              <ShoppingCartIcon   />
            </Badge>
          </IconButton>

          {/* 
          <Box sx={{ position: 'absolute', left: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


            <Box sx={{
              width: '5.5rem', height: '5.5rem', backgroundColor: theme.palette.primary.main, zIndex: 9999,
              display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 50, marginRight: 2
            }}>

              <ImageAvatar src={Avater} />
            </Box>
            <Typography variant='body1' sx={{ marginRight: 3 }}>
              user name
            </Typography>
          </Box> */}
        </Toolbar>

      </AppBar>


      <Drawer variant="permanent" component="paper" open={open} >
        {/* <img
            src={Logo}
            style={{
              width: '10rem',
              height: '2.5rem',
              alignSelf: 'start',
              position: 'absolute',
              top: 15,
              left: 6,
              right: 0,
              bottom: 0

            }}
          /> */}
        {/* <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor:'red',
            justifyContent: 'flex-end',
            px: [1],
          }}

        >
          <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
        </Toolbar> */}
        {/* <Divider /> */}
        <List component="nav" sx={{ alignItems: 'center', position: 'fixed', zIndex: 9999 }}>
          <ListItems />
          {/* <Divider sx={{ my: 1 }} /> */}
          <ListItemButton onClick={() => {



          }}>
            {/* <ListItemIcon>
                <LogoutIcon color='error' />
              </ListItemIcon>
              <ListItemText primary={t("logout")} /> */}


          </ListItemButton>




        </List>
        {/* <Divider /> */}

        {/* 
        <IconButton onClick={toggleDrawer} disableRipple sx={{ display: `${!open ? 'none' : 'flex'}`, marginTop: 3 }}>
          <ChevronLeftIcon />
          <div style={{ fontSize: 14, fontWeight: 600 }}>Hide</div>
        </IconButton> */}
      </Drawer>
      <Box
        // component="main"
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === 'light'
          //     ? theme.palette.grey[100]
          //     : theme.palette.grey[900],
          width: '100%',
          // flexGrow: 1,
          // height: '100vh',
          overflow: 'auto',
        }}
      >
        {/* <Toolbar /> */}

        {/* <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}> */}
        <Box sx={{ mt: 5, p: 5 }}>

          <ScreensNavigator />

        </Box>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}