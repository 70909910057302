import React from 'react'
import FileOrder from './FileOrder'
import OrderInputForm from './OrderInputForm'
import { useOrderContext } from 'context/OrderContext'
import { useUserOrderContext } from 'context/UserOrderContext'
export default function OrderFormPagination() {
    const { currentOrderDetails } = useOrderContext()
    const { userOrders } = useUserOrderContext()
    const [order, setOrder] = React.useState('')
  
    return (
        <div>
            {currentOrderDetails.ordertype === 'detail' ?
                <OrderInputForm />

                :
                <FileOrder />

            }

        </div>
    )
}
