import * as React from 'react';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useCartContext } from 'context/CartContext';
import { useOrderContext } from 'context/OrderContext';
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import { useAgencyContext } from 'context/AgencyContext';
import { LoadingButton } from '@mui/lab';
import { useUserAuth } from 'context/UserAuthContext';
import { useUserOrderContext } from 'context/UserOrderContext';
import { useNavigate } from 'react-router-dom';

// import { useOrderContext } from 'context/OrderContext';

const theme = createTheme();

export default function CheckoutForm() {

    const { createOrder, orderResponse, setOrdersResponse, isLoading, isSubmited, setIsSubmited } = useOrderContext()
    const { user } = useUserAuth()
    const { cartItems, setCartItems,resetCart } = useCartContext()
    const { agencyData } = useAgencyContext()
    const { t } = useTranslation()
    const { setIsOrderAdded } = useUserOrderContext()
    const navigate=useNavigate()

    //Yup Schema
    const schema = yup.object({

        email: yup.string().trim().email('Invalid Email').required("L'e-mail non può essere vuota"),
        fullName: yup.string().trim().required("Il nome non può essere vuoto"),
        phoneNo: yup.string().min(11,"Numero di telefono troppo breve").required("Numero di telefono richiesto")
    }).required();



    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        createOrder({ user: { ...data, user_id: user.user_id }, items: cartItems })
        // console.log(data)

        reset({
            email: "",
            fullName: "",
            country: "",
            city: '',
            phoneNo: ''

        });

    }
    //
    if (isSubmited === true) {
        setCartItems([])
        localStorage.removeItem('cartItems')
        setIsSubmited(false)
    }



    //CONDITION FOR ORDER
    if (orderResponse.sts === 'success') {

        swal(
            {
                title: `${t("success")}`,
                text: `Order submitted with id:${orderResponse.order_id}`,
                icon: 'success',
                button: 'ok'
            }
        )
        navigate('/')
        resetCart()
        setOrdersResponse('')
        setIsOrderAdded(true)

    }
    else if (orderResponse.sts === 'error') {

        swal(
            {
                title: `${t("error")}`,
                text: `${t("some_internal_error")}`,
                icon: 'error',
                timer: 1000,
                buttons: false
            }
        )
        setOrdersResponse('')


    }
    else if (orderResponse === 'error') {

        swal(
            {
                title: `${t("error")}`,
                text: `${t("some_internal_error")}`,
                icon: 'error',
                button: 'ok',


            }
        )
        setOrdersResponse('')

    }
    // console.log(user)


    //CHECK AGENCY FORM
    if (agencyData === null || agencyData.is_sts === 0) {

        swal(
            {
                title: `${t("error")}`,
                text: `${t("missing_agency_data")}`,
                icon: 'error',
                buttons: 'ok'
            }
        )
        setOrdersResponse('')

    }
    //gender and email section



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Paper
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 3
                    }}
                >

                    <Typography component="h1" color='primary' variant="h5" sx={{ m: 2 }}>
                        {t('user_info')}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2} sx={{ minHeight: '15rem' }}>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    defaultValue={user.fullname}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="fullName"
                                            // required
                                            fullWidth
                                            id="fullName"
                                            label={t("full_name")}

                                            error={errors.fullName}
                                            helperText={errors.fullName?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Controller
                                    name="email"
                                    defaultValue={user.email}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="email"
                                            label={t("email")}
                                            name="email"
                                            autoComplete="email"

                                            error={errors.email}
                                            helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Controller
                                    control={control}

                                    // defaultValue={user.phone}

                                    name="phoneNo"
                                    
                                    render={({ field: { onChange, value } }) =>
                                        < MuiPhoneNumber defaultCountry={'us'}
                                            sx={{ marginTop: 3 }}
                                            fullWidth
                                            onChange={onChange} value={value}
                                            // {...field}
                                            error={errors.phoneNo}
                                            helperText={errors.phoneNo?.message}
                                            options={{
                                                excludeCountries: [''],
                                                disableAreaCodes: false
                                            }}
                                        />
                                    }
                                />

                            </Grid>
                        </Grid>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            disabled={agencyData === null || agencyData.is_sts === 0}
                            loading={isLoading}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("invia_ordine")}
                        </LoadingButton>

                    </form>
                </Paper>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
        </ThemeProvider>
    );
}