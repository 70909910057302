import React from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import tEn from './Translation/tEn.json'
import tIt from './Translation/tIt.json'

import UserAuthContextProvider from "context/UserAuthContext.js";
import CartContextProvider from "context/CartContext.js";
import ProductContextProvider from "context/ProductsContext.js";
import OrderContextProvider from "context/OrderContext.js";
import UserOrderContextProvider from "context/UserOrderContext.js";
import FilterContextProvider from "context/FilterContex.js";
import AgencyContextProvider from "context/AgencyContext.js";
import ArticleContextProvider from "context/UserArticle";


i18n
  .use(initReactI18next)
  .init({

    resources: {
      en: {
        translation: tEn
      },
      it: {
        translation: tIt
      }
    },
    lng: "it",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });


ReactDOM.render(
  <>
    <UserAuthContextProvider>
      <CartContextProvider>
        <FilterContextProvider>
          <OrderContextProvider>
            <UserOrderContextProvider>
              <ProductContextProvider>
                <AgencyContextProvider>
                  <ArticleContextProvider>


                    <App />

                  </ArticleContextProvider>
                </AgencyContextProvider>
              </ProductContextProvider>
            </UserOrderContextProvider>
          </OrderContextProvider>
        </FilterContextProvider>
      </CartContextProvider>
    </UserAuthContextProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
