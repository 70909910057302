import React, { useContext, createContext } from "react";
import axios from 'axios';
// const BASE_URL = "https://inposizione.thewebconcept.com"
const BASE_URL="https://link.inposizione.it"

const ProductContext = createContext()
export default function ProductContextProvider({ children }) {
    const [products, setProducts] = React.useState([])
    const [categories, setCategories] = React.useState('')
    const [loadingProducts, setLoadingProducts] = React.useState(false)
    // const [themeValues, setThemeValues] = React.useState('')

    React.useEffect(() => {
        getProducts()
        getCategories()
    }, [])

    // console.log(products)

    // https://thewebconcept.com/inposizione/admin/api/product_list
    const getProducts = async () => {
        setLoadingProducts(true)
        await axios.get(`${BASE_URL}/admin/api/index.php?type=productlist`)
            .then(function (response) {
                // handle success
                // console.log(response.data);
                if (response.data !== null) {
                    setProducts(response.data)
                    setLoadingProducts(false)

                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
                
            });
    }

    // https://inposizione.thewebconcept.com/admin/api/index.php?type=themesandcategories
    //GET CATEGORIES AND THEME
    const getCategories = async () => {
        await axios.get(`${BASE_URL}/admin/api/index.php?type=themesandcategories`)
            .then(function (response) {

                setCategories(response.data.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }


    return (<ProductContext.Provider value={{ products, categories, loadingProducts }}>
        {children}
    </ProductContext.Provider>)

}
export const useProductContext = () => {
    return useContext(ProductContext)
}