import { Box, IconButton } from '@mui/material'
import React from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AgencyForm from './AgencyForm';
import PrivateForm from './PrivateForm';
import Container from '@mui/material/Container';
import { useUserAuth } from 'context/UserAuthContext';
import { useAgencyContext } from 'context/AgencyContext';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
// import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';

export default function UserForm({ setOpen }) {
    const { t } = useTranslation()

    const [currentScreen, setCurrentScreen] = React.useState('private')
    const { agencyRes, setAgencyRes, setLoadAgency ,show, setShow} = useAgencyContext()
    // const { user } = useUserAuth()

    //TRIGGER ALERT IF ANY ERROR OR SUCESS OCCUR

    if (agencyRes === 'error') {
        swal(
            {
                title: `${t("error")}`,
                // text: `${t("invalid_e_p")}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setAgencyRes('')
    }
    if (agencyRes === 'success') {

        swal(
            {
                title: `${t("sucess")}`,
                // text: `${t("")}`,
                icon: 'success',
                //  button:'ok'
            }
        )
        setLoadAgency(true)
        setAgencyRes('')
        setShow(false)
    }



    return (
        <Box sx={{ position: 'relative' }}>



            <Box sx={{ display: 'flex', width: '12rem', marginTop: 1, m: 3 }}>
                <ListItemButton onClick={() => setCurrentScreen('private')}
                    sx={{
                        backgroundColor: `${currentScreen === 'private' && '#1976D2'}`,
                        color: `${currentScreen === 'private' && 'white'}`, borderRadius: 1
                    }}
                    disableRipple
                >

                    <ListItemText primary="Privato" />
                </ListItemButton>
                <ListItemButton onClick={() => setCurrentScreen('agency')}
                    sx={{
                        backgroundColor: `${currentScreen === 'agency' && '#1976D2'}`,
                        color: `${currentScreen === 'agency' && 'white'}`, borderRadius: 1
                    }}
                    disableRipple
                >

                    <ListItemText primary="Azienda" />
                </ListItemButton>


                <IconButton color='error' size='large' sx={{ position: 'absolute', right: 2, top: -20 }}
                onClick={()=>setShow(false)}
                >

                    <ClearIcon fontSize="inherit"/>
                    {/* {agencyData !== null ? "Edit" : "Add"} */}
                </IconButton>
            </Box>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                {/* <AgencyForm/> */}
                {(() => {

                    switch (currentScreen) {
                        case 'private':
                            return <PrivateForm setOpen={setOpen} />
                        case 'agency':
                            return <AgencyForm setOpen={setOpen} />


                        default: return <PrivateForm />
                    }
                })()}

            </Container>

        </Box >
    )
}
