import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useUserOrderContext } from 'context/UserOrderContext';
import { useTranslation } from "react-i18next";
import OrderDetailPopUp from 'components/PopUp/OrderDetailPopUp';
import { IconButton } from '@mui/material';
import { useOrderContext } from 'context/OrderContext';
import Spinner from 'components/Spinner';
import { Box } from '@mui/material';
// import { useCartContext } from 'context/CartContext';


//Creat data for row
function createData(serial, order_id, pro_name, name_domain, link_publish, expirydate, total, order_sts, pro_additionalnotes, ordertype, item_img, destinationLink, review, anchorText,publisher_sts) {
  return { serial, order_id, pro_name, name_domain, link_publish, expirydate, total, order_sts, pro_additionalnotes, ordertype, item_img, destinationLink, review, anchorText ,publisher_sts};
}


// const userData = JSON.parse(window.localStorage.getItem('user'));

export default function Orders() {
  const { userOrders, ordersLoading } = useUserOrderContext()
  const { newOrder, setCurrentOrderDetails } = useOrderContext()
  // const {setCurrentOrder}=useCartContext()
  const { t } = useTranslation()

  //RELOAD API ON CHANGE ANY VALUE


  //GENERATE ROWS FOR TABLE
  const rows =
    userOrders.filter(item => item.order_sts != "completed").map((item, i) => {

      return createData(i, item.order_id, item.pro_name, item.name_domain, item.link_publish, item.expirydate,
        item.total, item.order_sts, item.pro_additionalnotes, item.ordertype, item.item_img, item.destinationLink, item.review, item.anchorText,item.publisher_sts)

    })
  // console.log(userOrders)

  //DETAIL MODAL REF
  const detailRef = React.useRef(null)
  const handleDetailRef = () => {
    detailRef.current.click()
  }


  return (
    <>{
      ordersLoading ?
        <Spinner />




        : <Box>


          {userOrders.length < 1 ?

            <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
              {t('empty')}
            </Typography>



            : <div>
              {/* <Typography variant='h6' color="primary">
          {t('order_summary')}
        </Typography>
        <Typography sx={{ display: `${!userOrders.length > 0 ? 'block' : 'none'}`, marginTop: 2 }} align='center'>
          {t('no_item')}
        </Typography> */}


              <TableContainer component={Paper} sx={{ display: `${userOrders.length > 0 ? 'block' : 'none'}` }}>

                <Table sx={{ worder_idth: '100%' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{'NUMERO ORDINE'}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">SITO WEB</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('domain_name')}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("publication_link")}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_start_date')}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("amount")}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_status")}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("publication_status")}</TableCell>
                      <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_detail")}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >

                        <TableCell align="center">{row.order_id}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>

                          <a href={`https://${row.pro_name}`} target="_blank">{row.pro_name}</a>


                        </TableCell>
                        <TableCell align="center">{row.name_domain}</TableCell>
                        <TableCell align="center">{row.link_publish}</TableCell>
                        <TableCell align="center">{row.expirydate}</TableCell>
                        <TableCell align="center" sx={{ color: 'primary' }}>{row.total
                        }</TableCell>
                        <TableCell align="center">
                          <div style={{
                            padding: 1, backgroundColor:
                              `${row.order_sts === 'pending' ? '#fff75e'
                                : row.order_sts === "completed" ? "#b2ff9e"
                                  : row.order_sts === 'published' ? '#e3f2fd'
                                    : "#f38375"}`
                            , borderRadius: '12px'
                          }}>

                            {t(row.order_sts)}
                          </div>

                        </TableCell>
                        <TableCell align="center">
                          <div style={{
                            padding: 1, backgroundColor:
                              `${row.publisher_sts === 'pending' ? '#fff75e'
                                : row.publisher_sts === "inrevision" ? "#f38375"
                                  : row.publisher_sts === 'approved' ? '#b2ff9e'
                                    : "#f38375"}`
                            , borderRadius: '12px'
                          }}>

                            {t(row.publisher_sts)}
                          </div>

                        </TableCell>
                        <TableCell align="center">

                          <IconButton variant="contained"
                            onClick={() => {

                              handleDetailRef()
                              setCurrentOrderDetails(row)
                              // console.log(row)
                            }
                            }
                          >

                            <InfoIcon color='primary' />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </TableContainer>

              <OrderDetailPopUp ref={detailRef} />
            </div>
          }
        </Box>
    }
    </>
  );
}

