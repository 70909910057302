import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useProductContext } from 'context/ProductsContext';
import { useTranslation } from 'react-i18next';
import { useUserOrderContext } from 'context/UserOrderContext';


function preventDefault(event) {
    event.preventDefault();
}

export default function Sales() {
    
    
    const { products } = useProductContext()
    const { t } = useTranslation()
    const {userOrders,dashboardOrders}=useUserOrderContext()
    // const grandTotal = userOrders.reduce((a, c) => a + (c.grandtotal * 1 ), 0)



  // React.useEffect(() => {
  //   counter(0, products.length)
  // }, [products])



  var utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');


  return (
    <React.Fragment>
      <Title>{t("total_sale")}</Title>
      <Typography component="p" variant="h3">
        {/* {grandTotal}&euro; */}
        {dashboardOrders.totalsale===null?0:dashboardOrders.totalsale}&euro;
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, marginTop: 4 }}>
        {utc}
      </Typography>
      <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
        <Typography color="primary"  onClick={preventDefault}>
          INPOSIZIONE.IT
        </Typography>
        <Typography color="primary" sx={{fontSize:18}} onClick={preventDefault}>
          {t('customer')}
        </Typography>
      </div>
    </React.Fragment>
  );
}