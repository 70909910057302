import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import avater from '../../assets/img/avater.png'
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function ProfileCard() {
  const userData = JSON.parse(window.localStorage.getItem('user'));
  const {t}=useTranslation()

  return (
    <CssVarsProvider>
      <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
        <CardOverflow>
          <Box sx={{ borderRadius: 20, width: '10rem', height: '10rem' }}>
            <img
              src={avater}
              // src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
              // srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
              loading="lazy"
              alt="profile"
              style={{ borderRadius: "50%", width: '10rem', height: '10rem' }}
            />
          </Box>
        </CardOverflow>
        <Typography level="h2" textAlign='center' color='primary' sx={{ fontSize: 'sm', mt: 2 }}>
             {userData.fullname}
        </Typography>
        <Typography level="body1"  textAlign='center' sx={{ fontSize: 'sm',mt: 0.5 }}>
          {userData.email}
        </Typography>
        <Typography level="body2"  textAlign='center' sx={{ fontSize: 'sm',mb: 0.5 }}>
          {userData.phone}
        </Typography>

      </Box>
    </CssVarsProvider>
  );
}