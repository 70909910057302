import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from 'context/UserAuthContext';
export default function ProtectedRoute({ Component }) {
    const navigate = useNavigate()
    const { isUserAuthenticated } = useUserAuth()
    React.useEffect(() => {
        // console.log(isUserAuthenticated)
        if (!isUserAuthenticated) { navigate('/login') }
        else{
            navigate('/')
        }
        

    }, [isUserAuthenticated])


    return (
        <div>
            <Component />
        </div>
    )


}
