import React from 'react'
import { Box } from '@mui/system'
import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useOrderContext } from 'context/OrderContext'
import { useUserOrderContext } from 'context/UserOrderContext'


export default function Sales() {
    const { t } = useTranslation()
    const theme = useTheme()
    const { userOrders, dashboardOrders, setDashboardOrders } = useUserOrderContext()
    const { newOrder } = useOrderContext()


    const styles = {
        paper: {
            // width: '15rem',
            height: '4rem',
            borderRadius: 3,
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        text: {
            fontSize: 13, color: theme.palette.text.secondary,fontWeight:'bold'
        },
        box: {
            height: '3.5rem',
            width: '4rem',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }
    }
    return (
        <Grid container spacing={4} alignItems={'center'}>

            <Grid item xs={12} sm={3} md={3} lg={3} >
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('article_to_be')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            ${dashboardOrders.articaltobepublished === null ? 0 : dashboardOrders.articaltobepublished}
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography variant='h6' sx={{ color: 'white' }} >
                            {dashboardOrders.articaltobepublished === null ? 0 : dashboardOrders.articaltobepublished}
                        </Typography>
                    </Box>

                </Paper>

            </Grid>



            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('waiting_for_config')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            ${dashboardOrders.pendingconformation === null ? 0 + newOrder : parseInt(dashboardOrders.pendingconformation, newOrder)}
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography variant='h6' sx={{color:'white'}}>
                            {dashboardOrders.pendingconformation === null ? 0 + newOrder : parseInt(dashboardOrders.pendingconformation, newOrder)}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>




            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('completed_orders')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            ${dashboardOrders.completedorder === null ? 0 : dashboardOrders.completedorder}
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box} >
                        <Typography variant='h6' sx={{color:'white'}}>
                            {dashboardOrders.completedorder === null ? 0 : dashboardOrders.completedorder}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>




            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('rejected_orders')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            ${dashboardOrders.rejectedorder === null ? 0 : dashboardOrders.rejectedorder}
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography variant='h6' sx={{color:'white'}}>
                            {dashboardOrders.rejectedorder === null ? 0 : dashboardOrders.rejectedorder}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>

            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('profit')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: 'white' }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}



            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('expense')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: 'white' }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}



            {/* 
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('tax')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: 'white' }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}




            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('total_sales')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: 'white' }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}


        </Grid>



    )
}
