import React from 'react'
import { Box } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useOrderContext } from 'context/OrderContext';

export default function OrderInputForm() {
    const { t } = useTranslation()
    const { currentOrderDetails } = useOrderContext()
    return (
        <Box sx={{ padding: 3 }}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        Link destinazione
                    </Typography>
                    <Typography variant="body2">
                        {currentOrderDetails.destinationLink}
                        {/* <a style={{ cursor: 'pointer' }} href={currentOrderDetails.link_publish} target="_blank">{currentOrderDetails.destinationLink}</a> */}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        {t('anchor_text')}
                    </Typography>
                    <Typography variant="body2">
                        {currentOrderDetails.anchorText}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        {t("further_info")}
                    </Typography>
                    <Typography variant="body2">
                        {currentOrderDetails.pro_additionalnotes}
                    </Typography>

                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        {t("expire_date")}
                    </Typography>
                    <Typography variant="body2">
                        {currentOrderDetails.expirydate}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        {t('item_review')}
                    </Typography>
                    <RadioGroup
                        row
                        value={currentOrderDetails.review}

                    >
                        <FormControlLabel value="yes" control={<Radio />} label="SI" />
                        <FormControlLabel value="no" control={<Radio />} label="NO" />


                    </RadioGroup>
                </Grid>

            </Grid>

        </Box>
    )
}
