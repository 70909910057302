import { Box, Grid, Input, Typography } from '@mui/material'
import React from 'react'
import { TextField } from '@mui/material'
import './style.css'
import CategorySelector from 'components/Seloctors/CategorySelector'
import LiguaSelector from 'components/Seloctors/LiguaSelector'
import GoogleN from 'components/Seloctors/GoogleN'
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next'
import { useFilterContext } from 'context/FilterContex'
import { useProductContext } from 'context/ProductsContext'
import ZaSlider from 'components/Sliders/ZaSlider'
import DaSlider from 'components/Sliders/DaSlider'
import { LoadingButton } from '@mui/lab'
import { useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';


export default function FilterForm() {

    const { handleFilter, setFilteredPro, cancel, setCancel, cancelSrch, resetFilter,
        setCancelSrch, setSearchName, setMinPrice, setMaxPrice, minPrice, maxPrice, setZaValue,
        setDaValue, setTheme, loading, filterdPro, activeFilter, status, setStatus } = useFilterContext()
    const { products } = useProductContext()
    const [min, setMin] = React.useState(minPrice)
    const [max, setMax] = React.useState(maxPrice)
    const [srch, setSrch] = React.useState('')
    const theme = useTheme()
    const [isReset, setIsReset] = React.useState(false)
    // const [daValue, setDaValue] = React.useState('')
    // const [zaValue, setZaValue] = React.useState('')

    //to reset other form
    const [reset, setReset] = React.useState(false)
    const { t } = useTranslation()

    const handleInputChange = (event) => {
        // console.log(Math.round((max + min) / 1))
        setMin(event.target.value === '' ? '' : Number(event.target.value));

    };
    const handleMaxInputChange = (event) => {
        setMax(event.target.value === '' ? '' : Number(event.target.value));

    };

    const handleBlur = () => {
        if (min < '0') {
            setMin('0');
        }
        if (max < 0) {
            setMax(0);
        }

    };


    React.useEffect(() => {
        // setPrice(Math.round((max + min) / 2))
        setMinPrice(min)
        setMaxPrice(max)

    }, [min, max])



    //handle searchinput
    const handleSearch = (text) => {

        if (text) {
            const newData = filterdPro.filter(item => {
                const itemData = item.pro_name ? item.pro_name.toUpperCase() : ''.toUpperCase()
                const textData = text.toUpperCase()
                return itemData.indexOf(textData) > -1;
                // console.log(itemData.indexOf(textData) > -1)
            })
            setFilteredPro(newData)


        }
        else {
            setFilteredPro(products)
            // console.log("there")
        }
    }

    //RESET FILTER STATE
    const restFilterStates = () => {
        setFilteredPro(products)
        setCancel(true)
        setTheme('')
        resetFilter()
        setMin('')
        setMax('')
        setReset(true)
        setZaValue(['', ''])
        setDaValue(['', ''])
        setIsReset(true)
        setStatus('')

    }


    return (
        <Box sx={{ marginBottom: "4rem" }}>

            {/* <Box sx={{ zIndex: 999, right: 20, top: 7, width: '100%', display: 'flex', alignItems: "center" }}>

                <input type='text'
                    style={{
                        border: `2px solid ${theme.palette.primary.main}`, padding: 10,
                        borderRadius: 5, width: '100%', margin: 'auto'
                    }}
                    placeholder={t('search_name')}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Box> */}
            {/* <Box sx={{ backgroundColor: '#1976D2', color: 'white', p: 1, borderRadius: 1, fontWeight: 600 }}>
                {t("search")}
            </Box> */}

            <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'space-between' }}>



                <Input
                    placeholder={t("search_name")}
                    fullWidth
                    // value={srch}

                    sx={{ marginLeft: 1, marginRight: 1 }}
                    onChange={(e) => handleSearch(e.target.value)}
                />

                {/* <Button variant="contained"
                    onClick={() => {
                        setSearchName(srch)
                        handleSearch(srch)
                        setCancelSrch(false)


                    }}
                    sx={{ display: `${cancelSrch ? 'block' : 'none'}` }}
                >
                    {t("search")}
                </Button> */}

                {/* <Button variant="contained"
                    color='error'

                    onClick={() => {
                        setCancelSrch(true)
                        setFilteredPro(products)
                        setSrch('')
                        setStatus('')

                    }}
                    sx={{ marginTop: 2, display: `${!cancelSrch ? 'block' : 'none'}` }}
                >
                    {t("cancel")}
                </Button> */}

            </Box>

            {/* FILTER GRID */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                <Typography align='center' variant='subpro_name1' sx={{ margin: 2 }}>
                    -{t("or")}-
                </Typography>
            </Box>
            <Grid container spacing={3} alignItems="center">

                {/* form  */}

                <Grid item xs={12} sm={6} md={3}>

                    <TextField id="outlined-basic" label={t("min_prise")}
                        variant="outlined"
                        value={min}
                        fullWidth
                        onChange={(value) => handleInputChange(value)}
                        onBlur={() => handleBlur()}
                        inputProps={{
                            step: 100,
                            min: 0,
                            type: 'number',
                            'aria-labelledby': 'input-slider',

                        }}
                        sx={{ margin: 1 }}
                    />
                </Grid>



                <Grid item xs={12} sm={6} md={3}>

                    <TextField id="outlined-basic" label={t("max_prise")} variant="outlined"

                        fullWidth
                        value={max}
                        onChange={(value) => handleMaxInputChange(value)}
                        onBlur={() => handleBlur()}
                        inputProps={{
                            step: 100,
                            min: 0,
                            type: 'number',
                            'aria-labelledby': 'input-slider',

                        }}
                        sx={{ margin: 1 }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>

                    <DaSlider isReset={isReset} setIsReset={setIsReset} />
                    {/* <InputSlider setDaValue={setDaValue} daValue={daValue} /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ZaSlider isReset={isReset} setIsReset={setIsReset} />
                    {/* <InputMaxSlider setZaValue={setZaValue} zaValue={zaValue} /> */}
                </Grid>

            </Grid>

            {/* </Box> */}
            <Grid container spacing={3} sx={{ mt: 2 }}>

                <Grid item xs={12} sm={4} md={3}>

                    <CategorySelector />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>

                    <LiguaSelector />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>

                    <GoogleN />
                </Grid>
                {/* Setting nested grid */}

                <Grid item xs={12} sm={12} md={3}>

                    <Box sx={{ mt: 1, justifyContent: 'space-around', display: 'flex', flexDirection: 'row-reverse' }} >

                        <LoadingButton
                            // loading={loading}
                            variant="contained"
                            // startIcon={<SearchIcon />}
                            // sx={{ marginRight: "0rem", float: 'right', display: `${cancel ? '' : ''}` }}
                            sx={{ marginRight: "0rem", float: 'right', display: `${cancel ? '' : ''}` }}
                            onClick={() => {

                                handleFilter(products)
                                // console.log(filterdPro)
                                setCancel(false)
                            }}
                        >
                            {t("search")}
                        </LoadingButton>
                        <Button
                            variant='contained'
                            color='error'
                            disabled={cancel}
                            // sx={{ marginRight: 1, float: 'right', display: `${!cancel ? 'block' : 'block'}` }}
                            sx={{ marginRight: 1, float: 'right' }}
                            onClick={() => {
                                restFilterStates()

                            }}
                        >
                            {t("cancel_filter")}
                        </Button>
                    </Box>



                </Grid>
            </Grid>


            {/* <Box sx={{ marginTop: 2 }}>

                <Button variant="contained" startIcon={<SearchIcon />}
                    sx={{ marginRight: "0rem", float: 'right' }}
                    onClick={() => {

                        handleFilter(products)
                        setCancel(false)
                    }}
                >
                    {t("search")}
                </Button>
                <Button
                    variant='contained'
                    color='error'
                    sx={{ marginRight: 2, float: 'right', display: `${!cancel ? 'block' : 'none'}` }}
                    onClick={() => {

                        setCancel(true)
                        setFilteredPro(products)
                        resetFilter()
                        setMin('')
                        setMax('')
                        setReset(true)
                        setZaValue('')
                        setDaValue('')


                    }}
                >
                    {t("cancel_filter")}
                </Button>
            </Box> */}
        </Box>
    )
}
