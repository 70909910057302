import * as React from 'react';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAgencyContext } from 'context/AgencyContext';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from 'context/UserAuthContext';

import swal from 'sweetalert'

const theme = createTheme();

export default function AgencyForm() {
    const { agencyData, agencyDataSubmit, agencyDataUpdata, setCurrentUser, isLoading } = useAgencyContext()

    const { user } = useUserAuth()
    const { t } = useTranslation()

    // console.log(agencyData)
    //Yup Schema
    const schema = yup.object({

        orgnization: yup.string().required("required"),
        city: yup.string().trim().min(3).required("required"),
        state: yup.string().trim().required("required"),
        address: yup.string().trim().required("required"),
        zip: yup.string().trim().required("required"),
        vatNumb: yup.string().trim().required("required"),
        state: yup.string().trim().required("required")
    }).required();



    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // setStorage({ ...data, type: 'organization', user_id: user.user_id })
        // setAgencyData({ ...data, type: 'organization' })
        // setCurrentUser({ id: user.user_id, type: 'organization' })
        //Condition to edit or add agency data
        if (agencyData === null || agencyData.is_sts === 0) {
            agencyDataSubmit({ ...data, type: 'organization', user_id: user.user_id })
        }
        else if (agencyData.is_sts === 1)
            agencyDataUpdata({ ...data, type: 'organization', user_id: user.user_id, user_agency_id: agencyData.user_agency_id })


        reset({
            orgnization: '',
            city: '',
            state: '',
            address: '',
            zip: '',
            vatNumb: '',
            country: ''

        })
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >


                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="orgnization"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="orgnization"
                                            // required
                                            fullWidth
                                            id="orgnization"
                                            label={t('orgnization')}
                                            defaultValue=""
                                            error={errors.orgnization}
                                        // helperText={errors.orgnization?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="vatNumb"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="vatNumb"
                                            // required
                                            fullWidth
                                            id="vatNumb"
                                            label="Partia IVA"
                                            defaultValue=""
                                            error={errors.vatNumb}
                                        // helperText={errors.orgnization?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="address"
                                            // required
                                            fullWidth
                                            id="address"
                                            label={t('address')}
                                            defaultValue=""
                                            error={errors.address}
                                        // helperText={errors.orgnization?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="city"
                                            label={t("city")}
                                            name="city"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.city}
                                        // helperText={errors.city?.message}
                                        />}
                                />
                            </Grid>



                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="zip"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="zip"
                                            label="CAP"
                                            name="zip"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.zip}
                                        // helperText={errors.state?.message}
                                        />} />
                            </Grid>





                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="state"
                                            label={t('state')}
                                            name="state"
                                            // autoComplete="email"
                                            defaultValue=""
                                            error={errors.state}
                                        // helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="country"
                                            label={t('proviance')}
                                            name="country"
                                            // autoComplete="email"
                                            defaultValue=""
                                            error={errors.state}
                                        // helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>


                        </Grid>
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {agencyData === null ? `${t("add")}` : `${t("edit")}`}
                        </LoadingButton>

                    </form>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
        </ThemeProvider>
    );
}