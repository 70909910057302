import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useArticleContext } from 'context/UserArticle';


const theme = createTheme();

export default function EditInput() {
    const { t } = useTranslation();
    const { addRevisionOrder, revisionLoading, currentArticle, revisionRes, setRevisionRes } = useArticleContext()
    //Yup Schema
    const schema = yup.object({
        addNote: yup.string().required(`${t("addNote_required")}`),
        // confirmaddNote: yup.string().trim().oneOf([yup.ref('addNote'), null], `${t('addNote_match')}`),

    }).required();


    // const { updateaddNote, loading, resStatus, setRevisionRes } = useUserAuth()
    const navigate = useNavigate()
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // console.log(data.addNote)
        addRevisionOrder(data.addNote, currentArticle)
        reset({
            addNote: '',

        })


    }



    //gender and email section
    // console.log(resStatus.sts)
    if (revisionRes.is_sts === 1) {

        swal(
            {
                title: `${"success"}`,
                text: `${revisionRes.msg}`,
                icon: 'success',
                timer: 1000,
                buttons: false
            }
        )
        setRevisionRes('')
        navigate('/article')
    }
    if (revisionRes.is_sts === 0) {
        swal(
            {
                title: `${"error"}`,
                text: `${revisionRes.msg}`,
                icon: 'error',
                timer: 1000,
                buttons: false
            }
        )
        setRevisionRes('')
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>

                    <Controller
                        name="addNote"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                // required
                                {...field}
                                fullWidth
                                multiline={true}
                                rows={4}
                                name={t('add_note')}
                                label={t("add_note")}
                                type="addNote"
                                id="addNote"
                                // autoComplete="new-addNote"
                                defaultValue=""
                                error={errors.addNote}
                                helperText={errors.addNote?.message}
                            />}
                    />

                    <LoadingButton
                        loading={revisionLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t("add")}
                    </LoadingButton>

                </form>

            </Container>
        </ThemeProvider>
    );
}

