import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Paper from '@mui/material/Paper';
import { Button, IconButton, Typography, Box } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate, useHistory } from 'react-router-dom';
import Swal from 'sweetalert'
import { useTranslation } from 'react-i18next';
import { useCartContext } from 'context/CartContext';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

//Creat data for row
function createData(serial, id, pro_name, pro_ip, pro_da, pro_price) {
    return { serial, id, pro_name, pro_ip, pro_da, pro_price };
}

//map will place here to map rows


export default function UserCart({ setCurrentScreen }) {
    const { cartItems, removeCartItem, resetCart } = useCartContext()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const rows =
        cartItems.map((item, i) => {
            //return row
            return createData(i, item.id, item.pro_name, item.pro_ip, item.pro_da, item.pro_price, item.pro_redazione, item.pro_note)

        })

    //launch SWAL

    const luchSwal = (id) => {
        Swal({
            title: `${t("sure_to_delete")}`,
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            showConfirmButton: false
        }).then((result) => {

            if (result) {
                removeCartItem(id)
                Swal({
                    icon: 'success',
                    title: `${t("deleted")}`,
                    text: `${t("item_deleted")}`,
                    timer: 1000,
                    buttons: false
                })
            }
            else {
                Swal(

                    {
                        title: `${t("safe_item")}`,
                        timer: 1000,
                        buttons: false
                    }
                )
            }
        })
    }

    return (
        <>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>

                <IconButton color='primary' onClick={() => navigate(-1)}>
                    <KeyboardReturnIcon />
                </IconButton>
                <Button color='error'
                sx={{display:`${cartItems.length<1&&'none'}`}}
                variant='outlined' onClick={() => resetCart()}
                    endIcon={<DeleteSweepIcon />}>
                        {t("sweep_cart")}
                </Button>
            </Box>
            <Typography sx={{ display: `${!cartItems.length > 0 ? 'block' : 'none'}`, marginTop: 1 }} align='center'>
                {t("no_item")}
            </Typography>
            <TableContainer component={Paper} sx={{ display: `${cartItems.length > 0 ? 'block' : 'none'}` }}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">ID </TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">SITO WEB </TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">IP</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">DA</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">PREZZO</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">{t('eleminate')}</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell align="center">{row.serial + 1}</TableCell>
                                <TableCell align="center">{row.pro_name}</TableCell>
                                <TableCell align="center">{row.pro_ip}</TableCell>
                                <TableCell align="center">{row.pro_da}</TableCell>
                                <TableCell align="center" sx={{ color: 'primary' }}>{row.pro_price}</TableCell>
                                <TableCell align="center"><IconButton variant="contained"
                                    onClick={() => {
                                        luchSwal(row.id)
                                    }}
                                >

                                    <DeleteForeverIcon color='error' />
                                </IconButton></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>


            <Button variant='contained' sx={{ marginBottom: 2, float: 'right', mt: 2, mr: 2 }}
                disabled={cartItems.length === 0}
                onClick={() => navigate('/checkOut')} >
                {t("place_order")}
            </Button>
        </>
    );
}

