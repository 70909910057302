import React from 'react'
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';
import AvatarImg from 'assets/img/avater.png'
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResetPassword from '../Settings/UpdataPassword';
import UserProfile from './UserProfile';
// import UserForm from 'components/Form/UserForm';
import { Grid } from '@mui/material';
import UserForm from 'components/Form/AgencyDetailForm/UserForm'

export default function Profile() {
  const { t } = useTranslation()
  // const userData = JSON.parse(window.localStorage.getItem('user'));

  return (
    
        <UserProfile />


  )
}
