import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFilterContext } from 'context/FilterContex';
import { useProductContext } from 'context/ProductsContext';
import { useTranslation } from 'react-i18next';

export default function LiguaSelector() {
  const { theme, setTheme } = useFilterContext()
  const { categories } = useProductContext()
  const {t}=useTranslation()

  const handleChange = (event) => {
    setTheme(event.target.value);
    console.log(event.target.value)
  };

  const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)

  }
  // console.log(theme)

  return (
    <FormControl sx={{  width: '100%',marginLeft:1 }} >
      <InputLabel id="demo-select-small">{t('theme')}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={theme}
        label="Ligua Selector"
        onChange={handleChange}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {!categories.themes ?
          null
          : categories.themes.map((item) => {
            return <MenuItem id={item.theme_id} value={item.theme_name}>{capitalizeWord(capitalizeWord(item.theme_name))}</MenuItem>
          })
        }
        {/* <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem> */}
      </Select>
    </FormControl>
  );
}
