import { Box, Typography } from '@mui/material'
import React from 'react'
import CartItemsCheck from './CartItemsCheck'
import CheckoutForm from './CheckoutForm'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useCartContext } from 'context/CartContext'
import { useTranslation } from 'react-i18next'
import {IconButton} from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function ChekOut() {
  const { cartItems } = useCartContext()
  const navigate=useNavigate()
  const { t } = useTranslation()
  return (
    <div>
      {/* <Typography align='left' variant='h6' sx={{ marginBottom: 2 }} color='primary'>
        {t("checkout")}
      </Typography> */}
      <IconButton color='primary' onClick={() => navigate('/userCart')}>
        <KeyboardReturnIcon />
      </IconButton>

      <Typography sx={{ display: `${!cartItems.length > 0 ? 'block' : 'none'}`, marginTop: 5 }} align='center'>
        {t("no_item")}
      </Typography>
      <Box sx={{ display: `${cartItems.length > 0 ? 'flex' : 'none'}`, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <Box sx={{ mt: 1 }}>

          <CartItemsCheck />
        </Box>
        <Box sx={{ mt: 1 }}>

          <CheckoutForm />
        </Box>
      </Box>
    </div>
  )
}
