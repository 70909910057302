import React from 'react'
import { Box } from '@mui/system'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import Row from './Row';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useUserOrderContext } from 'context/UserOrderContext';
import CkEditor from 'components/Editor/CkEditor';
import { useArticleContext } from 'context/UserArticle';

export default function EditorScrren() {
    const data = useLocation().state.data;
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { approveRes, setApproveRes,articles,setArticles } = useArticleContext()
    const { userOrders, setUserOrders } = useUserOrderContext()
    const [updatedText, setUpdateText] = React.useState('')

    // const decode = new TextEncoder().decode(data.writing_text);
    // console.log(userOrders)

    const encoded = new TextEncoder().encode(updatedText)
    //UPDATE ORDER LIST
    const updateArticleList = (id) => {
        setArticles(
            articles.map((item) =>
                item.order_item_id === id ? { ...item, writer_sts: 'approvedarticle' } : item

            )
        )
    }

    // console.log(userOrders)
    if (approveRes.is_sts === 0) {
        swal(
            {
                title: `${t("error")}`,
                text: `${approveRes.msg}`,
                icon: 'error',
                button: false,
                timer: 1000
            }
        )
        
        setApproveRes('')
    }
    if (approveRes.is_sts === 1) {
        swal(
            {
                title: `${t("order_added")}`,
                text: `${approveRes.msg}`,
                icon: 'success',
                button: false,
                timer: 1000
            }
        )
        updateArticleList(data.order_item_id)
        setApproveRes('')
        navigate('/article')
    }


    // console.log(data)
    return (
        <Box>
            <IconButton color='primary' sx={{ marginBottom: 2 }}
                onClick={() => navigate('/article')}
            >
                <KeyboardReturnIcon />
            </IconButton>

            <CkEditor data={data} setUpdateText={setUpdateText} />
        </Box>
    )
}
