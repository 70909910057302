import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "context/UserAuthContext";
import { Typography } from "@mui/material";
import swal from 'sweetalert'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoIcon from '../../assets/svg/Inposizione-Logo.png'
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useUserOrderContext } from "context/UserOrderContext";
import { useAgencyContext } from "context/AgencyContext";





export default function Login() {
    const { loginUser, isUserAuthenticated, resStatus, loading, setResStatus } = useUserAuth()
    const { setAuthDone, getOrder,userDashboard } = useUserOrderContext()
    const { setAuthAgency } = useAgencyContext()

    const navigate = useNavigate()
    const { t } = useTranslation();

    React.useEffect(() => {
        if (isUserAuthenticated) {

            navigate('/')
            //load user order and agency after auth
            setAuthDone(true)
            setAuthAgency(true)
        }
    }, [isUserAuthenticated])


    //validation Schema
    const schema = yup.object({
        password: yup.string().trim().min(5, `${t("short_password")}`).required(`${t("password_required")}`),
        email: yup.string().email(`${t("invalid_email")}`).required(`${t("email_required")}`),
    }).required();


    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });



    if (resStatus === 'loginError') {
        swal(
            {
                title: `${t("error")}`,
                text: `${t("invalid_e_p")}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setResStatus('')
    }
    if (resStatus === 'timeOut') {

        swal(
            {
                title: `${t("error")}`,
                text: `${t("check_connection")}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setResStatus('')
    }
    const user = JSON.parse(localStorage.getItem('user'))
    if (resStatus === 'auth') {
        // setAuthDone(true)
        if (user !== null)
        {
            getOrder()
            setResStatus('')
        }

    }


    //LOGIN USER USING AUTH CONTEXT
    const onSubmit = data => {

        loginUser(data)



    }



    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>

                <img
                    src={LogoIcon}
                    style={{
                        width: '19rem',
                        height: '5rem',
                    }}
                />
            </Box>

            <Paper elevation={3} sx={{
                display: 'flex', flexDirection: 'column', width: 345, margin: 'auto',
                p: 5
            }}>
                <Typography variant="h5" align="center">
                    {t('login')}
                </Typography>

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            error={errors.email}
                            id="margin-dense"
                            margin="normal"
                            label={t("email")}
                            defaultValue=""
                            autocomplete="off"

                            helperText={errors.email?.message}

                        />
                    }
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            error={errors.password}
                            id="standard-error-helper-text"
                            label={t("password")}
                            margin="normal"
                            type='password'
                            defaultValue=""
                            helperText={errors.password?.message}
                        />
                    }
                />
                <Box sx={{ fontSize: 14, alignSelf: 'end', marginTop: 1 }}>

                    <Link to="/forgetPassword"  >
                        {t("forgot_password")}
                    </Link>
                </Box>





                <LoadingButton loading={loading}
                    style={{ width: '6.5rem', margin: 'auto', marginTop: "1rem" }}
                    variant="contained"
                    type="submit">

                    {t('login')}
                </LoadingButton>
                <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

                    <Link to="/register"  >
                        {t("dont_have")}
                    </Link>
                </Box>
                {/* <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

                    <Link to="/landing"  >
                        {t("back_to_home")}
                    </Link>
                </Box> */}

            </Paper>
        </form>

    );
}
