import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFilterContext } from 'context/FilterContex';

export default function GoogleN() {
  const { gnews, setGnews } = useFilterContext()
  const handleChange = (event) => {
    setGnews(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%',marginLeft:1 }} >
      <InputLabel id="demo-select-small">Google News</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={gnews}
        label="Google News"
        onChange={handleChange}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        <MenuItem value={"si"}>Si</MenuItem>
        <MenuItem value={"no"}>No</MenuItem>

      </Select>
    </FormControl>
  );
}
