import * as React from 'react';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useCartContext } from 'context/CartContext';
import { useUserAuth } from 'context/UserAuthContext';
import swal from 'sweetalert';
import axios from 'axios';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/system';
// const BASE_URL = "https://inposizione.thewebconcept.com"
const BASE_URL = "https://link.inposizione.it"

// console.log(process.env.REACT_APP_BASE_URL)
export default function CartFileUpload() {
  const [file, setFile] = React.useState(null);
  const [isUpload, setIsUpload] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [url, setUrl] = React.useState(null);
  const [res, setRes] = React.useState('');
  // const [isFile, setIsFile] = React.useState(false)
  const { t } = useTranslation()
  const { setIsSubmited, setOpen, addToCart, currentItem } = useCartContext()
  // const { user } = useUserAuth()

  //Yup Schema
  const schema = yup.object({

    // publicationData: yup.string().required("Name required"),
    furtherForm: yup.string().trim().required("City required"),
  }).required();





  //DATE INPUT
  let currentDate = new Date();
  const presentDate = currentDate.getMonth() + 1 + '/' + currentDate.getDate() + '/' + currentDate.getFullYear()
  const [value, setValue] = React.useState(dayjs(`${presentDate}`));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  //FILE INPUT CHANGE 
  const onFileChange = (e) => {

    if (e.target.files.length > 0) {
      setFile(e.target.files[0])


    }
  }
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('image', file);
    setLoading(true)
    await axios({
      method: 'post',
      url: `${BASE_URL}/admin/api/index.php?type=fileupload`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        // console.log( response)
        // if (response.data.is_sts === 1) {

        // }
        setUrl(response.data.url)
        setIsUpload(true)
        setRes(response.data)
        setLoading(false)

      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)


      });

  }
  //FROM CONTROLS
  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = data => {

    // console.log({ currentItem: currentItem, userFormData: { ...data, uploadFile: url, date: value } })
    addToCart({ ...currentItem, userFormData: { ...data, uploadFile: url, expirydate: value.$d } })
    // createOrder({ product_id: currentItem.id, user_id:user.user_id,userFormData: data, uploadFile: file })
    setIsSubmited(true)
    setOpen(false)
  }
  if (res.is_sts === 0) {

    swal(
      {
        title: `${t("error")}`,
        text: `${t("unable_upload_file")}`,
        icon: 'error',
        timer: 1000,
        buttons: false
      })

    setIsUpload(false)
    setRes('')
  }
  if (res.is_sts === 1) {

    swal(
      {
        title: `${t("success")}`,
        text: `${t("file_added")}`,
        icon: 'success',
        timer: 1000,
        buttons: false
      }

    )
    setRes('')
  }


  React.useEffect(() => {
    // console.log(file)
  }, [file])

  return (

    // <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />

    <div >
      {/* {t('upload_img_dox')} <span className="font-css top"></span> */}
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center' }}>

        <input type="file" id="file-input" name="ImageStyle"
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg,.pdf,.xlsx,.xls,.csv"
          onChange={(e) => onFileChange(e)} />
        <LoadingButton variant='contained'
          loading={loading}
          disabled={isUpload || file === null && url !== null}
          onClick={() => handleUpload()}>
          Authenticate
        </LoadingButton>
      </Box>
      {/* <LoadingButton variant="contained" sx={{marginTop:4,float:'right'}}
      // loading="true"
      >
        {t('upload')}
      </LoadingButton> */}

      <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>



          <Grid item xs={12} sm={12} >
            <LocalizationProvider

              dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                minDate={new Date()}
                label={t('publication_date')}
                inputFormat="MM/DD/YYYY"
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
              />
            </LocalizationProvider>
          </Grid>


          <Grid item xs={12} sm={12}>
            <Controller
              name="furtherForm"
              control={control}
              render={({ field }) =>
                <TextField
                  sx={{ color: 'black' }}
                  {...field}
                  autoComplete="given-name"
                  name="furtherForm"
                  // required
                  fullWidth
                  id="furtherForm"
                  label={t("further_info")}
                  multiline
                  rows={4}
                  defaultValue=""
                  error={errors.furtherForm}
                // helperText={errors.publicationData?.message}
                />}
            />
          </Grid>









        </Grid>
        <LoadingButton
          disabled={!isUpload}
          type="submit"
          // loading="true"
          fullWidth
          variant="contained"
          sx={{ mt: 5, mb: 2 }}
        >
          Fatto
        </LoadingButton>

      </form>
    </div>

  );
}