import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFilterContext } from 'context/FilterContex';
import { useTranslation } from 'react-i18next';
import { useProductContext } from 'context/ProductsContext';

export default function CategorySelector() {

  const { category, setCategory } = useFilterContext()
  const { categories } = useProductContext()
  const { t } = useTranslation()
  const handleChange = (event) => {
    setCategory(event.target.value);

  };


  const capitalizeWord = (word) => {

    const firstLetter = word.charAt(0)

    const firstLetterCap = firstLetter.toUpperCase()

    const remainingLetters = word.slice(1)

    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  return (
    <FormControl sx={{  width: '100%',marginLeft:1 }} >
      <InputLabel id="demo-select-small">{t('category')}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={category}
        label="Categoria"
        fullWidth
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {!categories.categories ?
          null
          : categories.categories.map((item) => {
            return <MenuItem id={item.categories_id} value={item.categories_name}>{capitalizeWord(capitalizeWord(item.categories_name))}</MenuItem>
          })
        }
        {/* <MenuItem value="auto">Auto</MenuItem>
        <MenuItem value='case'>Case</MenuItem>
        <MenuItem value='travel'>Travel</MenuItem>
        <MenuItem value='fortune'>Cartomanzia</MenuItem>
        <MenuItem value='food'>Food</MenuItem>
        <MenuItem value='fashion'>Moda</MenuItem>
        <MenuItem value='calcio'>Calcio</MenuItem>
        <MenuItem value='sport'>Sport</MenuItem>
        <MenuItem value='tech'>Tech</MenuItem>
        <MenuItem value='markieting'>Marketing</MenuItem>
        <MenuItem value='economy'>Economia</MenuItem>
        <MenuItem value='finance'>Finanza</MenuItem> */}
      </Select>
    </FormControl>
  );
}

// Auto
// casa
// Travel
// Cartomanzia
// Food
// Moda
// Calcio
// Sport
// Tech
// Marketing
// Economia
// Finanza