import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { useFilterContext } from 'context/FilterContex';

// function valuetext(value) {
//   return `${value}°C`;
// }

export default function ZaSlider({ isReset, setIsReset }) {
  const { zaValue, setZaValue } = useFilterContext()

  const [value, setValue] = React.useState([0, 100]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setZaValue(newValue)
  };

  React.useEffect(() => {
    if (isReset) {
      setValue([0, 100])
      setIsReset(false)
      setZaValue([0,100])
    }
  }, [isReset])

  return (
    <Box sx={{ width: '100%' }}>
      <Typography id="input-slider" variant='subtitle2' align='center' >
        ZA: {zaValue[0] === '' ? 0 : zaValue[0]}-{zaValue[1] === '' ? 0 : zaValue[1]}
      </Typography>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        // value={zaValue === '' ? zaValue : value}
        value={value}
        onChange={handleChange}
        // valueLabelDisplay="auto"
      // getAriaValueText={valuetext}
      />
    </Box>
  );
}
