import React, { useContext, createContext } from "react";
import axios from 'axios';
// const BASE_URL = "https://inposizione.thewebconcept.com"
const BASE_URL = "https://link.inposizione.it"

const UserOrderContext = createContext()
export default function UserOrderContextProvider({ children }) {
    const [userOrders, setUserOrders] = React.useState([])
    const [authDone, setAuthDone] = React.useState(false)
    const [isOrderAdded, setIsOrderAdded] = React.useState(false)
    const [dashboardOrders, setDashboardOrders] = React.useState('')
    const [dashbaordLoading, setDashbaordLoading] = React.useState(false)
    const [ordersLoading, setOrdersLoading] = React.useState(false)

    const userData = JSON.parse(window.localStorage.getItem('user'));

    React.useEffect(() => {
        // console.log(userData)

        if (userData !== null) {
            setAuthDone(false)

            userDashboard()
            getOrder()
        }
    }, [authDone])

    //RELOAD ORDERS ON CHANGE LOGIN OR PLACE NEW ORDER
    React.useEffect(() => {

        if (userData !== null) {
            getOrder()
            setIsOrderAdded(false)
        }
    }, [authDone, isOrderAdded])







    // GET USER ORDERS WITH DETAILS
    const getOrder = async () => {
        setDashbaordLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=userorders&user_id=${userData.user_id}`)
            .then(function (response) {
                // console.log(response.data.data)
                if (response.data.data !== null) {

                    setDashbaordLoading(false)
                    setUserOrders(response.data.data)
                }

            })
            .catch(function (error) {
                console.log(error);


            });
    }


    //GET DASHBOARD ORDERS
    const userDashboard = async () => {
        setDashbaordLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=userdashboard&user_id=${userData.user_id}`)
            .then(function (response) {
                if (userData !== null) {
                    // console.log(response.data.data)
                    setDashboardOrders(response.data.data)
                    setDashbaordLoading(false)
                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }





    return (<UserOrderContext.Provider value={{
        userOrders, setUserOrders, dashboardOrders,
        userDashboard,
        setDashboardOrders, setAuthDone, setIsOrderAdded, getOrder, dashbaordLoading, ordersLoading, setOrdersLoading
    }}>
        {children}
    </UserOrderContext.Provider>)

}
export const useUserOrderContext = () => {
    return useContext(UserOrderContext)
}