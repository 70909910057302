import { Typography } from '@mui/material';
import React from 'react'
import { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';


const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function SaleChart() {

  const theme = useTheme()
  const {t}=useTranslation()
  return (
    <>
      <ResponsiveContainer sx={{ ml: 2 }}>
        <BarChart data={data}>
          {/* <Legend /> */}
          {/* <YAxis/> */}
          <XAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          // margin={{ top: 5, right: 30, left: 55, bottom: 20 }}
          // tick={{fill:theme.palette.primary.main}}
          // dx={0}
          >
            {/* <Label
              angle={45}
              position='left'
              dy={10}
              offset={-7}

              style={{
                textAnchor: 'start',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >

              Sales
            </Label> */}
          </XAxis>
          <Bar dataKey="uv" fill="#bdbdbd" />
        </BarChart>
      </ResponsiveContainer>
      {/* <Typography>
        sales
      </Typography> */}
    </>
  )
}



