import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "context/UserAuthContext";
import { Grid, Typography } from "@mui/material";
import swal from 'sweetalert'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoIcon from '../../../assets/svg/Inposizione-Logo.png'
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';






export default function Verifycode({ setScreen }) {
    // const navigate = useNavigate()
    const { t } = useTranslation();
    const { passCode, setPassCode, loading } = useUserAuth()



    //validation Schema
    const schema = yup.object({
        code: yup.string().required(`${t("code_required")}`),
    }).required();


    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });



    if (passCode.is_sts === 0) {

        swal(
            {
                title: `${t("error")}`,
                text: `${t("check_connection")}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        // setPassCode('')

    }
    if (passCode.is_sts === 1) {

        // swal(
        //     {
        //         title: `${t("success")}`,
        //         text: `${t("password_send_to_code")}`,
        //         icon: 'success',
        //         buttons: false,
        //         timer: 1000
        //     }
        // )
        // setScreen(1)
        // setPassCode('')
    }

    const onSubmit = data => {
        if (passCode.code === +data.code) {
            setScreen(2)
        } else {
            swal(
                {
                    title: `${t("error")}`,
                    text: `${t("wrong_code")}`,
                    icon: 'error',
                    //  button:'ok'
                }
            )
        }


        // console.log(passCode.code===data.code)

    }



    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>

                <img
                    src={LogoIcon}
                    style={{
                        width: '19rem',
                        height: '5rem',
                    }}
                />
            </Box>

            <Paper elevation={3} sx={{
                display: 'flex', flexDirection: 'column', width: 345, margin: 'auto',
                p: 5
            }}>
                <Typography sx={{ fontSize: 16, textAlign: 'center' }}>
                    {t('enter_code')}
                </Typography>
                <Controller
                    name="code"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            error={errors.code}
                            id="margin-dense"
                            margin="normal"
                            label={t("verifyCode")}
                            defaultValue=""
                            //   autocomplete="off"

                            helperText={errors.code?.message}

                        />
                    }
                />


                <LoadingButton loading={loading}
                    style={{ width: '6.5rem', margin: 'auto', marginTop: "1rem" }}
                    variant="contained"
                    type="submit"
                // onClick={() => forgetPassword()}
                >

                    {t('send')}
                </LoadingButton>



                <Box sx={{ display: 'flex', fontSize: 16, justifyContent: 'center', marginTop: 5 }}>

                    <Link to="/login"  >
                        {t("login")}
                    </Link>
                </Box>

                {/* <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

          <Link to="/"  >
            {t("back_to_home")}
          </Link>
        </Box> */}

            </Paper>
        </form>

    );
}
