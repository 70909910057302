import React from "react";
import { Helmet } from "react-helmet";
import './style.css'
// Screens
import {
  HashRouter as Router, Route, Routes
} from "react-router-dom";

import { Box } from "@mui/system";
import LinearProgressBar from "components/Progress Bar/LinearProgressBar";
import Dashboard from "screens/Dashboard/Dashbaord";
import Login from "screens/LandingFrontend/Login";
import SignUp from "screens/LandingFrontend/Signup";
import ForgetPasswordNavigator from "screens/LandingFrontend/ForgetPassword/ForgetPasswordNavigator";
import { useUserOrderContext } from "context/UserOrderContext";

export default function App() {
  // console.log(process.env.REACT_APP_BASE_URL)
  const { dashbaordLoading } = useUserOrderContext()
  return (
    <>
      {/* <UserAuthContextProvider>
        <CartContextProvider>
          <FilterContextProvider>
            <OrderContextProvider>
              <UserOrderContextProvider>
                <ProductContextProvider>
                  <AgencyContextProvider>
                    <ArticleContextProvider>
 */}
      <>
      {/* {dashbaordLoading ?

        <Box sx={{ marginTop: '40vh' }}>
          <LinearProgressBar />

        </Box>
        : 
        } */}

        <Router>

          <Routes>
            <Route path='/*' element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forgetPassword" element={<ForgetPasswordNavigator />} />

          </Routes>
        </Router>
      </>
      {/* </ArticleContextProvider>
                  </AgencyContextProvider>
                </ProductContextProvider>
              </UserOrderContextProvider>
            </OrderContextProvider>
          </FilterContextProvider>
        </CartContextProvider>
      </UserAuthContextProvider> */}
    </>
  );
}

