import React, { useContext, createContext } from "react";
import axios from 'axios';

// const BASE_URL="https://inposizione.thewebconcept.com"
const BASE_URL = "https://link.inposizione.it"

const ArticleContext = createContext()
export default function ArticleContextProvider({ children }) {
    const [articles, setArticles] = React.useState([])
    const [articleLoading, setArticleLoading] = React.useState(false)
    const [approveArticleLoading, setApproveArticleLoading] = React.useState(false)
    const [revisionLoading, setRevisionArticleLoading] = React.useState(false)
    const [revisionRes, setRevisionRes] = React.useState('')
    const [approveRes, setApproveRes] = React.useState('')
    const [currentArticle, setCurrentArticle] = React.useState('')

    const userData = JSON.parse(window.localStorage.getItem('user'));
    React.useEffect(() => {
        userData != null &&
            getArticleOrder()
    }, [])

    // GET USER ORDERS WITH DETAILS
    const getArticleOrder = async () => {
        setArticleLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=getcustomerarticle&user_id=${userData.user_id}`)
            .then(function (response) {
                // console.log(response)
                if (response.data !== null) {

                    setArticles(response.data)
                }
                setArticleLoading(false)

            })
            .catch(function (error) {
                console.log(error);


            });
    }


    // const createTextOrder = async (editorContent, order_item_id) => {


    //     setIsLoading(true)
    //     await axios.post(`${BASE_URL}/admin/api/index.php?type=uploadarticlecustomer`, {
    //         user_id: user.user_id,
    //         order_id: order_item_id,

    //     })
    //         .then(function (response) {
    //             console.log(response)
    //             console.log(response.data)
    //             setOrdersResponse(response.data)
    //             setNewOrder(1)
    //             setIsLoading(false)
    //             // setIsSubmited(true)

    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             setIsLoading(false)
    //             setOrdersResponse('error')

    //         });
    // }


    //for note reviaion
    const addRevisionOrder = async (editorContent, order_item_id) => {

        setRevisionArticleLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=inrevisionarticle`, {
            order_id: order_item_id,
            revisionnote: editorContent

        })
            .then(function (response) {
                setRevisionRes(response.data)
                setRevisionArticleLoading(false)

                setArticles(
                    articles.map((item) =>
                        // item.order_item_id == order_item_id ? { ...item, order_sts: 'pendingarticle',revisionnote:editorContent  } : item
                        item.order_item_id == order_item_id ? { ...item, revisionnote: editorContent } : item

                    ))

            })
            .catch(function (error) {
                console.log(error);


            });
    }

    //APPROVE NOTE
    const approveArticle = async (order_item_id) => {

        setApproveArticleLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=approvedarticle&order_item_id=${order_item_id}`)
            .then(function (response) {
                // console.log(response)
                setApproveRes(response.data)
                setApproveArticleLoading(false)

            })
            .catch(function (error) {
                console.log(error);



            });
    }




    return (<ArticleContext.Provider value={{
        articles, setArticles, currentArticle,
        setCurrentArticle, articleLoading, setArticleLoading, getArticleOrder, approveArticle,
        approveArticleLoading, addRevisionOrder, revisionLoading, revisionRes, setRevisionRes, approveRes, setApproveRes
    }}>
        {children}
    </ArticleContext.Provider>)

}
export const useArticleContext = () => {
    return useContext(ArticleContext)
}